import { render, staticRenderFns } from "./MSPPM814P.vue?vue&type=template&id=dd2d2538&scoped=true&"
import script from "./MSPPM814P.vue?vue&type=script&lang=js&"
export * from "./MSPPM814P.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd2d2538",
  null
  
)

export default component.exports