/*
 * 업무구분: 사고보험금 조회
 * 화 면 명: MSPPM811M
 * 화면설명: 
 *           1. 사고보험금 조회 > 접수현황 탭(서류추가버튼클릭) > 본인인증 > 구비서류
 *           2. 사고보험금 조회 > 접수중단건(서명버튼) > 이어하기 > 본인인증 > 구비서류
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.03
 * 작 성 자: 김진원
 */
<template>
    <MSPPM812P v-if="currentStep === 1" :selfAthntSendData="selfAthntSendData" :selectedItem="$route.params.selectedItem" @pre="fn_pre" @next="fn_Step1Result"></MSPPM812P>
    <MSPPM814P v-else-if="currentStep === 2" :datas="$route.params.datas" :selectedItem="$route.params.selectedItem" @pre="fn_pre" @success="fn_Success"></MSPPM814P>
</template>
<script>
/***********************************************************************************
* 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import MSPPM812P from '@/ui/pm/MSPPM812P' // STEP1. 본인인증 (휴대폰 / 카카오)
import MSPPM814P from '@/ui/pm/MSPPM814P' // STEP2. 구비서류

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  name: "MSPPM811M",
  screenId: "MSPPM811M",
  components: {
    MSPPM812P, // STEP1. 본인인증 (휴대폰 / 카카오)
    MSPPM814P, // STEP2. 구비서류

  },

  /***********************************************************************************
    * Life Cycle 함수 정의 영역	                                                     *
    ***********************************************************************************/
  activated() {},
  created() {
    console.log('MSPPM811M > created > route.params info : ', this.$route.params)
    /**
     * params: {
     *   custType: 'A', 'B', '' //A 나 B 는 피수상이 케이스에서 A 는 피보험자, B 는 수익자 이고, empty 는 본인 또는 자녀인경우이다.
     *   datas: {
     *     menuType: '01', //01:본인, 02:피수상이, 03:자녀
     *     custInfoInsrd: {}, //피보자 정보(사고자)
     *     custInfoContr: {}, //계약자 정보(수익자)
     *   },
     *   selectedItem: {}, //접수목록에서 서류접수 버튼을 클릭한 ROW 정보
     * }
     */
    if(this.$route.params){
      const parameter = this.$route.params

      //피수상이의 경우중 피보험자 인 경우
      if( parameter.custType === 'A' ){ // 피보험자
        this.selfAthntSendData = parameter.datas.custInfoInsrd
      } else { // 피수상이의 수익자 또는 본인 또는 자녀 인 경우 계약자 정보로 본인인증.
        this.selfAthntSendData = parameter.datas.custInfoContr
      }
    }
  },
  mounted() {},
  props:{},

  /***********************************************************************************
  * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data() {
    return {
      prePage: '',
      currentStep: 1, // 현재 스텝
      selfAthntSendData: {}, //본인인증 해야 할 고객정보
    }
  },
  /***********************************************************************************
  * Computed 함수 정의 영역                                                         *
  ***********************************************************************************/
  computed: {},
  /***********************************************************************************
  * 사용자 함수 정의 영역                                                           *
  ***********************************************************************************/
  methods: {
    /**
     * @name fn_pre
     * @description 이전
     */
    fn_pre(){
      this.$router.go(-1)
    },


    /**
     * @name fn_Step1Result
     * @description 본인인증 결과
     */
    fn_Step1Result(){
      this.currentStep++
    },

    /**
     * @name fn_Success
     * @description 구비서류 업로드 완료
     */
    fn_Success(){
      this.$router.go(-1)
    }

  }
};
</script>
<style scoped>
</style>